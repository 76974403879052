<script setup lang="ts"></script>

<template>
  <div class="hay-layout--error">
    <div class="hay-layout__full">
      <slot name="full" />
    </div>
    <div class="hay-layout__content">
      <main class="hay-layout__main">
        <slot />
      </main>
    </div>
  </div>
</template>

<style scoped lang="scss">
.hay-layout {
  $this: &;
  &--error {
    display: flex;
    flex-direction: column;

    @extend .hay-layout;
    #{$this}__content {
      @include container;

      margin-top: var(--content-top-margin, 0);
    }
    #{$this}__main {
      overflow: hidden;
    }
  }
}
</style>
